$hero-full-screen-bg: url('../img/shop-video-poster.jpg') center center no-repeat;
$hero-full-screen-bg-size: contain;
$hero-full-screen-text-color: $white;

.hero-full-screen-shop {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // background: $hero-full-screen-bg;
  background-size: $hero-full-screen-bg-size;

  video#bgvid {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../img/shop-video-poster.jpg) no-repeat;
    background-size: cover;
  }

  .top-content-section {
    width: 100%;
    text-align: center;
    color: $hero-full-screen-text-color;
  }

  .middle-content-section {
    text-align: center;
    color: $hero-full-screen-text-color;
    position: absolute;
    top: 20%;
    .small {
      font-size: 13px;
    }
  }

  .bottom-content-section {
    padding: .5rem;
    position: relative;
    z-index: 1001;
    svg {
      height: rem-calc(40);
      width: rem-calc(40);
      fill: rgba($hero-full-screen-text-color, 0.77);
    }
  }

  // optional demo styles
  .top-bar {
    background: transparent;

    .menu {
      background: transparent;
    }

    .menu-text {
      color: $hero-full-screen-text-color;
    }

    .menu li {
      display: flex;
      align-items: center;
    }

    .menu a {
      color: $hero-full-screen-text-color;
      font-weight: bold;
    }
  }
}
