$sticky-shrinknav-menu-height: rem-calc(80);
$sticky-shrinknav-hero-height: 300px;

body {
  padding: 0;
}

.sticky-shrinknav-menu {
  @include horizontal-center;
  bottom: 0;
  height: $sticky-shrinknav-menu-height;
  line-height: $sticky-shrinknav-menu-height;
  width: 100%;
  background-color: rgba($black, 0.1);
  transition: all 0.5s ease;

  li {
    border-radius: 2px;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 0 1px $white;
    }
  }

  a {
    color: $white;
  }
}

.sticky-shrinknav-header-title {
  transition: all 0.3s ease;
  position: relative;
  transform: translateY(-$sticky-shrinknav-menu-height/2);
  margin-bottom: 0;
  color: $white;
}

.sticky-shrinknav-header {
  width: 100%;
  height: $sticky-shrinknav-hero-height;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  top: 58%;
  left: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  padding: 0 5%;

  img.logo {
    width: 80%;
    height: auto;
  }
}

body.sticky-shrinknav-wrapper {
  padding-top: 130px;

  .sticky-shrinknav-header {
    height: $sticky-shrinknav-menu-height;
    background-color: rgba($secondary-color, 0.89);
    top: 0;
    z-index: 1000;
    padding: 7px 0;

    .sticky-shrinknav-header-title {
      transform: scale(0);
      transition: all 0.3s ease;
    }
  }
}
