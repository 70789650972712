.product-image-gallery {
  background-color: $white;
  padding: 1.0rem;
}

.pdp-product-image {
  margin-bottom: 20px;
}

.product-thumbs {
  
  a {
    margin-left: 8px;
    margin-right: 8px;
    padding: 0 !important;
    
    img {
      @include thumbnail;
      width: 60px;
      height: 60px;
      border: none;
    }
  }
}