body {
  background-color: $black;
  overflow-x: hidden;
}

main#main {
  background-color: $white;
  padding-top: 60px;
  > section {
    margin-bottom: 3em;
    &#contact {
      margin-bottom: 0;
    }
    @include clearfix;
  }
  &.trouble {
    padding-top: 0;
  }
}

#revealVessel {
  position: relative;
  padding: 0;
  top: 10px;
  background-color: $white;
  .logo {
    position: fixed;
    width: 100%;
    height: $sticky-shrinknav-menu-height;
    background-color: rgba($secondary-color, 0.89);
    top: 0;
    padding: 7px 0;
    margin-bottom: 3em;

    @media print, screen and (min-width: 40em) {
      width: 600px;
      max-width: 75rem;
    }
    &.trouble  {
      position: relative;
      @media print, screen and (min-width: 40em) {
        width: 100%;
        height: $sticky-shrinknav-menu-height;
        max-width: 100%;
      }
    }
  }
  .body {
    font-family: Cousine, monospace;
    width: 100%;
    margin: 7rem 0 3rem 0;
    padding: 0 1.5rem;
    p, a {
      font-size: 14px;
    }
  }
  .close-button {
    color: $white;
    top: 7px;
    right: 14px;
  }
}

#faces {
  padding: 2rem 1rem;
  &.four-up-feature {
    text-align: center;

    .four-up-feature-header {
      margin-bottom: 3rem;
      display: flex;
    }

    .four-up-feature-image {
      margin-bottom: 2rem;
      // height: 150px;
      // width: 150px;
      width: 90%;
      height: auto;
    }

    .four-up-feature-text {
      margin-bottom: 2rem;
    }
  }
}

#jrrfAudio {
  padding: 0.5rem ;
  margin: 0 1rem 1rem 1rem;
}

#intro {
    p {
        font-size: 1.5rem;
    }
}