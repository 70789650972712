.scroll-to-top {
  display: none;
  position: fixed;
  bottom: 93px;
  right: 23px;
  z-index: 1000;
  background: #ffffff;
  border: 1px solid $white;
  background: $primary-color;
  cursor: pointer;
  height: 50px;
  width: 50px;
  box-shadow: 0px, 1px, 5px, 0, $black, 0.4;
  svg {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -10px;
    margin-top: -10px;
    color: $white;
    transform: rotate(90deg);
  }
  &:hover {
    border: 1px solid $primary-color;
    background: $white;
    svg {
      color: $primary-color;
    }
  }
  @include breakpoint (small only){
    bottom: 1rem;
    right: 1rem;
    width: 40px;
    height: 40px;
  }
}