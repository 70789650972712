$marketing-site-footer-bg: $dark-gray;

.marketing-site-footer {
  background: $marketing-site-footer-bg;
  color: lighten($marketing-site-footer-bg, 40%);
  padding: 2rem 0 0;

  .marketing-site-footer-menu-social {
    margin-top: 2rem;
    justify-content: center;

    a {
      color: $primary-color;
      font-size: 3.5rem;
      margin-right: 1.5rem;
    }
    li:last-child a {
      margin-right: 0;
    }
  }

  .column-block {
    margin-bottom: 30px;
  }

  .columns {
      margin-top: 1rem;
      margin-bottom: 3rem;
  }

}

.marketing-site-footer-name {
  color: $white;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.marketing-site-footer-title {
  color: $white;
  margin-bottom: 1rem;
  font-size: 1.75rem;
}

.marketing-site-footer-block {
  display: flex;
  margin-bottom: 1rem;

  .fa {
    font-size: 2rem;
    color: $secondary-color;
    margin-top: -7px;
  }

  p {
    margin-left: 1rem;
    line-height: 1.125rem;
  }
}

.marketing-site-footer-bottom {
  background: darken($marketing-site-footer-bg, 20%);
  padding: 1rem 0;

  .small {
    font-size: 13px;
    color: lighten($marketing-site-footer-bg, 20%);
  }

  p,
  .menu {
    margin-bottom: 0;
  }

  .marketing-site-footer-bottom-links {
    justify-content: center;

    a {
      font-size: 14px;
      color: lighten($marketing-site-footer-bg, 60%);
      padding-top: 0.25rem;
    }

    @include breakpoint(medium down) {
      justify-content: center;
    }
  }

  @include breakpoint(medium down) {
    text-align: center;
  }
}
