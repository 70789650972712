$hero-full-screen-bg: url('../img/JRRF_Flyer_A6_RZ_web.jpg') center center no-repeat;
$hero-full-screen-bg-size: contain;
$hero-full-screen-text-color: $white;

.hero-full-screen-trouble {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $hero-full-screen-bg;
  background-size: $hero-full-screen-bg-size;

  .middle-content-section {
    text-align: center;
    color: $hero-full-screen-text-color;
    position: absolute;
    bottom: 20%;
  }

  .top-content-section {
    width: 100%;
  }

  .bottom-content-section {
    padding: .5rem;
    position: relative;
    z-index: 1001;
    svg {
      height: rem-calc(40);
      width: rem-calc(40);
      fill: rgba($hero-full-screen-text-color, 0.77);
    }
  }

  // optional demo styles
  .top-bar {
    background: transparent;

    .menu {
      background: transparent;
    }

    .menu-text {
      color: $hero-full-screen-text-color;
    }

    .menu li {
      display: flex;
      align-items: center;
    }

    .menu a {
      color: $hero-full-screen-text-color;
      font-weight: bold;
    }
  }
}
