
// this one sets up the custom branch

@charset "utf-8";

@packageVersion '.:PACKAGE_VERSION:.';

@import 'settings';

// custom settings
@import 'custom/settings';

@import 'foundation';
@import 'motion-ui';
@import 'selectric';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

// vendor styles
@import "vendor/aos";
@import "vendor/slick-carousel";
@import "vendor/index-menu";

// mixins
@import 'bourbon'; // @see https://www.bourbon.io/docs/
@import 'mixins/container-border';
@import 'mixins/flex';
@import 'mixins/avatar';
@import 'mixins/helpers';
@import 'mixins/pseudo';
@import 'mixins/push-center';
@import 'mixins/sidelines';
@import 'mixins/zindex';
@import 'mixins/link-colors';

// blocks
@import 'blocks/sticky-shrinknav';
@import 'blocks/hero-full-screen';
@import 'blocks/hero-full-screen-trouble';
@import 'blocks/hero-full-screen-store';
@import 'blocks/ecommerce-hero-slider-small';
@import 'blocks/clean-hero-slider';
@import 'blocks/block-list';
@import 'blocks/mobile-app-icon-bar';
@import 'blocks/marketing-site-footer';
@import 'blocks/ecommerce-product-image-gallery';
@import 'blocks/hover-link';

// custom branch
@import 'custom/layout';
@import 'custom/scrolltop';
@import 'custom/ecwid-store';
@import 'custom/legal';

// for the 'tagesgeschäft'
@import 'custom/tweaks';

