.ecommerce-hero-slider-small {
  .orbit-previous,
  .orbit-next {
    color: $primary-color;
    font-size: 1.25rem;
  }
  .orbit-previous {
    left: -10px;
  }
  .orbit-next {
    right: -10px;
  }

  .orbit-bullets button {
    height: 0.65rem;
    width: 0.65rem;
    background-color: $secondary-color;
  }

  .orbit-bullets button.is-active {
    background-color: $primary-color;
  }

  .hero-slider-slide {
    background-color: $white;
    padding: 1.5rem; 
  }

  .hero-slider-slide-content {
    h2, h3 {
      margin-top: 1.5rem;
    }
    p {
      // font-size: 0.9rem;
      // color: $dark-gray;
    }
  }
}
