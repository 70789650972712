$image-hover-wrapper-transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
$image-hover-wrapper-transition-delay: 0.25s;
$image-hover-wrapper-banner-width: 75%;
$image-hover-wrapper-banner-topspace: 25%;
$image-hover-wrapper-banner-bg: rgba($primary-color ,0.8);
$image-hover-wrapper-icon-size: 24px;

.image-hover-wrapper {
  position: relative;

  a {
    display: inline-block;
    letter-spacing: normal;
    line-height: initial;
    margin: 0;
    padding: 0;
    position: relative;

    img {
      float: left;
      overflow: hidden;
      width: 100%;
    }

    .image-hover-wrapper-reveal {
      background-color: rgba(0, 0, 0, 0.5);
      color: $white;
      display: inline;
      height: 100%;
      margin-left: -100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 80;
      transition: $image-hover-wrapper-transition;
      transition-delay: $image-hover-wrapper-transition-delay;

      &:hover {
        opacity: 1;
        transition: $image-hover-wrapper-transition;
      }

      &:hover p {
        opacity: 1;
        transform: translateY(-100%);
        transition: $image-hover-wrapper-transition;
        transition-delay: $image-hover-wrapper-transition-delay;
      }

      p {
        bottom: 0;
        display: block;
        opacity: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        transition: $image-hover-wrapper-transition;
      }
    }

    .fa {
      font-size: $image-hover-wrapper-icon-size;
    }
  }

  .image-hover-wrapper-banner {
    background-color: $image-hover-wrapper-banner-bg;
    color: $white;
    font-weight: 600;
    left: 0;
    padding: 0.5rem;
    position: absolute;
    top: $image-hover-wrapper-banner-topspace;
    width: $image-hover-wrapper-banner-width;
    z-index: 10;
  }
}

