// Custom tweaks for JRRF

.debug {
  border: 3px solid red!important;
}

.font-roboto {
  // font-family: Roboto Condensed, san-serif!important;
  font-weight: bold!important;
}

.emphasized {
  font-weight: 700!important;
}

.font-cousine {
  font-family: Cousine, monospace!important;
}

.side-padding {
  padding: 0 1.5rem!important;
}

.button-dark {
  background-color: $secondary-color;
  color: $primary-color;
}

h2, .h2 {
    font-size: 2.5rem;
        @media print, screen and (max-width: 40em) {
            font-size: 2.0rem;
        }
}

.product-holder {
    width: 100%;
    margin: 4em 0;
}