body.legal  {
  background-color: $white;
  color: $secondary-color;
  > footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
   }
}

header.legal {
  width: 100%;
  height: $sticky-shrinknav-hero-height;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  padding: 0;

  img.logo {
    width: 80%;
    height: auto;
  }
  @include breakpoint(medium down) {
    padding: 0 0.5rem;
  }
}

.blog-post {
  margin-top: 2rem;
  margin-bottom: 15rem;

  h2 {
    margin-bottom: 2rem;
  }

  > div {
    margin: 2rem 0;
  }

  @include breakpoint(medium down) {
    padding: 0 1rem;
  }
}