.ec-cart-widget {
  position: absolute;
  top: 0;
  .ec-minicart__icon {
   background: rgba($hero-full-screen-text-color, 0.77)!important;
  }
}

.sticky-shrinknav-header {
  .ec-cart-widget {
    top: 69%;
    right: 10%;
  }
}

.sticky-shrinknav-wrapper {
  .ec-cart-widget {
    top: auto;
    right: 5%;
  }
}


//  legals tweaks
html#ecwid_html body#ecwid_body .ec-size .ec-store .ec-link--muted, html#ecwid_html body#ecwid_body .ec-size .ec-store .ec-link--muted:visited {
  color: $alert-color!important;
  transition: color .12s ease-out 0s;
}

html#ecwid_html body#ecwid_body .ec-size .ec-store .ec-link--muted:hover {
  color: lighten($alert-color, 33%)!important;
}

html#ecwid_html body#ecwid_body .ec-size .ec-store .grid__description {
  margin-bottom: 2rem;
  p {
    font-size: 24px;
  }
}